import { Heading } from '@chakra-ui/react';
import { FontSizes } from 'chakra-ui/fsmTheme';

const Header1 = ({ children, ...rest }) => {
  return (
    <Heading
      fontSize={[FontSizes.header1.mobile, FontSizes.header1.desktop]}
      lineHeight={['99%', '99%', '88px']}
      letterSpacing={['-1.4px', '-1px']}
      {...rest}
    >
      {children}
    </Heading>
  );
};

export default Header1;
