import { Stack } from '@chakra-ui/react';
import Accordion from '@components/ra/interactive/Accordion';
import Header2 from '@components/ra/typography/Header2';
import { antlerStyle } from '@constants/styles';

const DEFAULT_QUESTIONS = [
  {
    header: 'What does this cost me?',
    description:
      "Using Fair Square doesn't cost you a thing. Really. Once signed up for an insurance plan, there are naturally associated costs, (premiums, monthly payments, etc.). However, it's our job to find you the best deal out there on those payments.",
  },
  {
    header: 'How do you choose what plans to recommend?',
    description:
      "We prioritize plans that cover all your doctors, prescriptions, and pharmacies. Then we look at getting you the best price. Regardless of whether we get paid, we'll recommend you the best plan for your case that we can enroll.",
  },
  {
    header: 'How does the reshopping process work?',
    description:
      "Every year at certain times, you're eligible to switch Medicare plans, and we take care of that process for you. By reshopping your plan every year, we ensure that your plans continues to cover your needs and you get the best price.",
  },
];

const FAQS = ({ data = null, ...rest }) => {
  return (
    <Stack pt={[140, 160]} px={antlerStyle.px} bg="tan" spacing="60px" alignItems="center" {...rest}>
      <Header2>FAQS</Header2>
      <Accordion data={data || DEFAULT_QUESTIONS} />
    </Stack>
  );
};

export default FAQS;
