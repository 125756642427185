import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import Body1 from '../typography/Body1';
import care from '@public/ra/icons/primary/desktop/care.svg';
import Image from 'next/image';

const FreeServiceTag = ({ ...rest }) => {
  return (
    <Flex gap={4} alignItems="center" {...rest}>
      <Box position="relative">
        <Image width={29} height={29} src={care} alt="Health Icon" />
      </Box>
      <Body1 textAlign="left">Our service is 100% free with no hidden fees.</Body1>
    </Flex>
  );
};

export default FreeServiceTag;
