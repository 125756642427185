import { Box, Grid, Stack } from '@chakra-ui/react';
import Header2 from '@components/ra/typography/Header2';
import Header3 from '@components/ra/typography/Header3';
import { antlerStyle } from '@constants/styles';
import Image, { StaticImageData } from 'next/image';

type RightsData = {
  header: string;
  iconSrc: StaticImageData;
  iconAlt: string;
  renderDescription: () => React.ReactNode;
};

type Props = {
  rightsData: RightsData[];
};

const BaseBillOfRights = ({ rightsData }: Props) => {
  return (
    <Box py={['60px', '60px', 160]} px={antlerStyle.px} bg="white" border="10px solid" borderColor="red.400">
      <Stack w="100%" maxW="1400px" mx="auto">
        <Header2>Your Medicare Bill of Rights</Header2>
        <Grid templateColumns={['1fr', 'repeat(2, 1fr)']} pt={['40px', '40px', 20]} gap={['40px', '40px', 20]}>
          {rightsData.map(({ header, renderDescription, iconSrc, iconAlt }) => (
            <Stack key={header} spacing={0}>
              <Box width={['40px', '40px', '60px']} height={['40px', '40px', '60px']}>
                <Image src={iconSrc} width={60} height={60} alt={iconAlt} />
              </Box>
              <Box pt={[8, 8, 10]}>
                <Header3>{header}</Header3>
              </Box>
              <Box pt={[4, 4, 6]}>{renderDescription()}</Box>
            </Stack>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default BaseBillOfRights;
