import { keyframes } from '@chakra-ui/react';

export const border1Keyframes = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  10% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  30% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
`;

export const client1Keyframes = keyframes`
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  10% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  40% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
`;

export const client1Animation = `${client1Keyframes} 13s infinite`;

export const border1Animation = `${border1Keyframes} 13s infinite`;
