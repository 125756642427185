import { Flex, Stack } from '@chakra-ui/react';
import CallToAction from '@components/ra/common/landingPages/CallToAction';
import Steps from '@components/ra/common/Steps';
import Header2 from '@components/ra/typography/Header2';
import Image, { StaticImageData } from 'next/image';

const BaseWhatToExpect = ({ header = null, stepsData }) => {
  const renderImage = (image: StaticImageData, alt: string) => (
    <Flex justifyContent="center">
      <Image src={image} alt={alt} width={0} height={0} style={{ width: 'auto', maxHeight: '280px' }} />
    </Flex>
  );

  return (
    <Stack pt={['72px', '72px', '72px', 160]} bg="tan" alignItems="center" spacing="60px">
      <Stack maxW={['330px', '330px', '600px']} alignItems="center" spacing={6}>
        <Header2 textAlign="center">{header ?? "Here's what to expect with Fair Square"}</Header2>
        <CallToAction />
      </Stack>
      <Steps data={stepsData} renderImage={renderImage} />
    </Stack>
  );
};

export default BaseWhatToExpect;
