import { Box, Flex, Stack } from '@chakra-ui/react';
import CallToAction from '@components/ra/common/landingPages/CallToAction';
import Body1 from '@components/ra/typography/Body1';
import Header2 from '@components/ra/typography/Header2';
import check from '@public/ra/icons/checkmark.svg';
import Image from 'next/image';

const TextContent = ({ subheader }) => {
  return (
    <Stack
      maxW="600px"
      order={[3, 3, 3, 2]}
      alignItems="center"
      spacing={4}
      width={['100%', '100%', '100%', '60%']}
      pt={[6, 6, 8, 0]}
    >
      <Box>
        <Header2 textAlign="center">Let's get you on the right Medicare plan</Header2>
      </Box>
      <Box maxW="500px">
        <Body1 textAlign="center">{subheader}</Body1>
      </Box>
      <CallToAction pt={2} />
      <Flex gap={4} pt="42px" alignItems="center" justifyContent="center">
        <Box position="relative">
          <Image width={29} height={29} src={check} alt="Checkmark" />
        </Box>
        <Flex w={['90%', '90%', 'auto']}>
          <Body1 lineHeight="115%">Our service is 100% free with no hidden fees</Body1>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default TextContent;
