import { Box, Flex, Grid } from '@chakra-ui/react';
import Highlight from '@components/ra/interactive/Highlight';
import Body1 from '@components/ra/typography/Body1';
import { CarrierLogos } from '@constants/index';
import { antlerStyle } from '@constants/styles';
import Image from 'next/image';

const Carriers = () => {
  return (
    <Flex
      bg="blue"
      paddingX={[
        antlerStyle.mobilePx,
        antlerStyle.mobilePx,
        antlerStyle.mobilePx,
        `calc(${antlerStyle.desktopPx} - 10px)`,
      ]}
      paddingY={[10, 10, '80px']}
    >
      <Flex
        gap={[8, 8, '50px']}
        w="100%"
        maxW="1400px"
        mx="auto"
        flexDir={['column', 'column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          flex={1}
          maxW={['640px', '640px', '640px', '800px', '640px']}
          display="inline"
          sx={{ ' *': { display: 'inline' } }}
          lineHeight="300%"
          justifyContent={['center', 'center', 'center', 'flex-start']}
          textAlign={['center', 'center', 'center', 'center', 'left']}
        >
          <Highlight
            text="Medicare Parts A & B [+], Medigap [+], and Medicare Advantage [+] plans from the following carriers and more:"
            queries={[
              {
                key: 'Parts A & B [+]',
                description:
                  'Part A covers hospitalization, skilled nursing facility care, nursing home care (inpatient care in a skilled nursing facility that’s not custodial or long-term care), hospice care, and home health care. Part B covers medically necessary services (aka services or supplies that are needed to diagnose or treat a medical condition), and preventive services (aka health care to prevent illness or detect it at an early stage).',
              },
              {
                key: 'Medigap [+]',
                description:
                  'Also known as a Medicare Supplement, a Medigap Plan picks up the bill after Original Medicare (Parts A and B) pays. Medigap is offered by private insurance carriers and its premium is paid in addition to a premium for Part B.',
              },
              {
                key: 'Medicare Advantage [+]',
                description:
                  'Medicare Advantage plans are alternatives to Original Medicare (Parts A and B). They’re provided by private companies and often include drug coverage (Part D). Typically, using a Medicare Advantage plan limits the user to in-network providers.',
              },
            ]}
            renderText={(text) => <Body1>{text}</Body1>}
          />
        </Box>
        <Grid
          w="100%"
          templateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr', 'repeat(4, 1fr)']}
          px={[8]}
          gap={[8, 24, 20, 20, 14]}
          flex={2}
          alignItems="center"
          justifyContent={'center'}
          filter="brightness(0%)"
          sx={{
            '> *': { height: 'auto', margin: 'auto 0px' },
          }}
        >
          <Flex justifyContent="center">
            <Image src={CarrierLogos.aetna} alt="Aetna Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.wellcare} alt="Wellcare Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.anthem} alt="Anthem Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.cigna} alt="Cigna Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.uhc} alt="Unitedhealth Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.mutualOfOmaha} alt="Omaha Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.bcbs} alt="Blue Cross Blue Shiel Logo" />
          </Flex>
          <Flex justifyContent="center">
            <Image src={CarrierLogos.humana} alt="Humana Logo" />
          </Flex>
          <Flex justifyContent="center" display={['none', 'none', 'none', 'flex', 'none']}>
            <Image src={CarrierLogos.regence} alt="Regence Logo" />
          </Flex>
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Carriers;
