import { keyframes } from '@chakra-ui/react';

export const border2Keyframes = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  40% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  60% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
`;

export const client2Keyframes = keyframes`
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  30% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  70% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
`;

export const client2Animation = `${client2Keyframes} 13s infinite`;

export const border2Animation = `${border2Keyframes} 13s infinite`;
