import { AspectRatio, Box, Stack } from '@chakra-ui/react';
import Body2 from '@components/ra/typography/Body2';
import useWindowDimensions from '@hooks/useWindowDimensions';
import christy from '@public/enhanced/christy-blur.png';
import mikeJulie from '@public/enhanced/mike-julie-blur.png';
import sheryl from '@public/enhanced/sheryl.jpg';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { border1Animation, client1Animation } from './client1Keyframes';
import { border2Animation, client2Animation } from './client2Keyframes';
import { border3Animation, client3Animation } from './client3Keyframes';
import Square from './Square';
import { FsmColors } from 'chakra-ui/fsmTheme';

const HeroAnimation = () => {
  const { width } = useWindowDimensions();

  const clientKeyframes = [client1Animation, client2Animation, client3Animation];
  const borderKeyframes = [border1Animation, border2Animation, border3Animation];

  const rootRef: any = useRef();
  const [squareWidth, setSquareWidth] = useState(null);

  const getPixels = (squares: number) => `${squares * squareWidth}px`;

  useEffect(() => {
    if (rootRef && rootRef.current) {
      setSquareWidth(
        parseInt(window.getComputedStyle(rootRef.current.parentElement, null).width.replace('px', '')) / 6
      );
    }
  }, [width]);

  const renderClient = ({
    name,
    year,
    imageSrc,
    imageAlt,
    nameColor,
    borderColor,
    left = null,
    right = null,
    bottom = null,
    top = null,
    index = 0,
  }) => {
    return (
      <Box
        bottom={bottom !== null && bottom}
        top={top !== null && top}
        left={left !== null && left}
        right={right !== null && right}
        ref={rootRef}
      >
        <Box position="relative">
          {/* Border */}
          <Box
            position="absolute"
            bottom={0}
            left={left !== null && 0}
            right={right !== null && 0}
            bg={borderColor}
            width={`${squareWidth + 0.3}px`}
            height={`${squareWidth + 0.2}px`}
            animation={borderKeyframes[index]}
          ></Box>
          {/* Image */}
          <Box
            position="absolute"
            w={`${squareWidth * 3 - 24}px`}
            h={`${squareWidth * 3 - 24}px`}
            top={top !== null && '12px'}
            bottom={bottom !== null && '12px'}
            left={left !== null && '12px'}
            right={right !== null && '12px'}
            animation={clientKeyframes[index]}
          >
            <AspectRatio ratio={1} w={`${squareWidth * 3 - 24}`} h={`${squareWidth * 3 - 24}`}>
              <Image src={imageSrc} alt={imageAlt} width={400} height={400} style={{ position: 'absolute' }} />
            </AspectRatio>
          </Box>
        </Box>
        {/* Text */}
        <Box
          display={['none', 'none', 'none', 'block']}
          position="absolute"
          bottom={0}
          left={left !== null && '-.6px'}
          right={right !== null && '-.6px'}
          py={1.5}
          px={4}
          bg={nameColor}
          animation={clientKeyframes[index]}
        >
          <Stack spacing={0} whiteSpace="nowrap">
            <Body2 bold>{name}</Body2>
            <Body2>{year}</Body2>
          </Stack>
        </Box>
      </Box>
    );
  };
  return (
    <Box w="70%" pb="70%" position="relative" sx={{ '> *': { position: 'absolute' } }}>
      {/* Left Cluster */}
      <Square width={getPixels(1)} left={0} bottom={getPixels(3)} bg="navy" />
      <Square width={getPixels(1)} left={getPixels(1)} bottom={getPixels(4)} bg="blue" />
      {/* Top Cluster */}
      <Square width={getPixels(1)} right={getPixels(1)} top={0} bg="blue" />
      <Square width={getPixels(1)} right={0} top={getPixels(1)} bg="teal" />
      {/* Right Cluster */}
      <Square width={getPixels(1)} right={0} bottom={getPixels(1)} bg="red.400" />
      <Square width={getPixels(1)} right={getPixels(1)} bottom={getPixels(2)} bg="navy" />

      {/* Clients */}
      {renderClient({
        imageSrc: mikeJulie,
        imageAlt: 'FSM Clients Mike & Julie',
        year: 2023,
        name: 'Mike & Julie',
        nameColor: 'mint',
        borderColor: 'teal',
        left: 0,
        bottom: 0,
        index: 0,
      })}
      {renderClient({
        name: 'Christy',
        year: 2023,
        imageSrc: christy,
        imageAlt: 'FSM Client Christy',
        nameColor: 'teal',
        borderColor: 'red.400',
        right: getPixels(2),
        bottom: getPixels(3),
        index: 1,
      })}
      {renderClient({
        name: 'Sheryl',
        year: 2023,
        imageSrc: sheryl,
        imageAlt: 'FSM Client Sheryl',
        nameColor: 'teal',
        borderColor: 'blue',
        left: getPixels(3),
        bottom: getPixels(1),
        index: 2,
      })}
    </Box>
  );
};

export default HeroAnimation;
