import { Box, Flex } from '@chakra-ui/react';
import { antlerStyle } from '@constants/styles';
import cb from '@public/enhanced/cb.png';
import laurie from '@public/enhanced/laurie.png';
import Image from 'next/image';
import TextContent from './TextContent';

const BaseCallUs = ({ subheader }) => {
  return (
    <Flex
      py={[140, 140, 160]}
      px={antlerStyle.px}
      bg="tan"
      justifyContent="center"
      alignItems="center"
      flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
      gap={['6%', '6%', '6%', '120px']}
    >
      <Box
        display={['flex', 'flex', 'flex']}
        order={[1, 1, 1, 1]}
        flexBasis={['47%', '47%', '47%', '20%']}
        outline={['4px solid', '8px solid']}
        outlineColor={['blue', 'blue']}
      >
        <Image src={laurie} alt="Laurie Headshot" style={{ width: '100%' }} />
      </Box>
      <TextContent subheader={subheader} />
      <Box
        display={['flex', 'flex', 'flex']}
        order={[2, 2, 2, 3]}
        flexBasis={['47%', '47%', '47%', '20%']}
        outline={['4px solid', '8px solid']}
        outlineColor={['red.400', 'red.400']}
      >
        <Image src={cb} alt="Chris Headshot" style={{ width: '100%' }} />
      </Box>
    </Flex>
  );
};

export default BaseCallUs;
