import { Box, Flex, Stack } from '@chakra-ui/react';
import FreeServiceTag from '@components/ra/common/FreeServiceTag';
import CallToAction from '@components/ra/common/landingPages/CallToAction';
import Body1 from '@components/ra/typography/Body1';
import Header1 from '@components/ra/typography/Header1';
import Carriers from './Carriers';

import period from '@public/ra/period.svg';
import Image from 'next/image';
import HeroAnimation from './HeroAnimation';
import React from 'react';

const BaseHero = ({
  header,
  subheader,
  renderCta = null,
  renderImage = () => null,
  hideCarriers = false,
  imageOverTextOnMobile = true,
}) => {
  const renderHeader = () => {
    const startOfHeader = header.substring(0, header.lastIndexOf(' ') + 1);
    const lastWord = header.substring(header.lastIndexOf(' ') + 1, header.length);

    return (
      <>
        <Header1 as="h1" display="inline">
          {startOfHeader}{' '}
        </Header1>
        <Header1 style={{ display: 'inline' }} whiteSpace="nowrap">
          {lastWord}
          <Box display="inline-block" as="span" width={['12px', '12px', '16px', '16px']} ml={[2, 2, 2, 2]}>
            <Image src={period} alt="Stylistic Period" style={{ display: 'inline' }} />
          </Box>
        </Header1>
      </>
    );
  };

  const renderHeroImage = () => renderImage() ?? <HeroAnimation />;

  return (
    <>
      <Box bg="white" border="10px solid" borderColor="blue" paddingX={['18px', '24px', '24px', '80px']}>
        <Flex
          w="100%"
          maxW="1400px"
          mx="auto"
          flexWrap="wrap"
          flexDir={['column-reverse', 'column-reverse', 'column-reverse', 'row']}
          pt={imageOverTextOnMobile ? [4, 4, 8, '80px'] : [10, 10, 8, '80px']}
          pb={[4, 4, 8, '80px']}
          alignItems="center"
        >
          <Stack width={['100%', '100%', '100%', '50%']} pb="32px" spacing={8}>
            <Stack spacing={4} display="inline">
              {renderHeader()}
              <Box>{typeof subheader === 'string' ? <Body1>{subheader}</Body1> : subheader}</Box>
            </Stack>
            <Flex>{renderCta ? renderCta() : <CallToAction alignItems="start" />}</Flex>
            <Flex
              width="100%"
              justifyContent="center"
              display={imageOverTextOnMobile ? 'none' : ['flex', 'flex', 'none']}
            >
              {renderHeroImage()}
            </Flex>
            <FreeServiceTag />
          </Stack>
          <Flex
            width={['100%', '100%', '100%', '50%']}
            justifyContent="center"
            py={[8, 8, 12, 12]}
            display={imageOverTextOnMobile ? 'flex' : ['none', 'none', 'flex']}
          >
            {renderHeroImage()}
          </Flex>
        </Flex>
      </Box>
      {!hideCarriers && <Carriers />}
    </>
  );
};

export default BaseHero;
