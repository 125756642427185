import { keyframes } from '@chakra-ui/react';

export const border3Keyframes = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  70% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  80% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  90% {
    -webkit-transform: scale(3);
            transform: scale(3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
`;

export const client3Keyframes = keyframes`
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  70% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  90% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
`;

export const client3Animation = `${client3Keyframes} 13s infinite`;

export const border3Animation = `${border3Keyframes} 13s infinite`;
